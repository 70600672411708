<template>
    <div class="box">
        <el-form
            ref="form"
            label-width="180px"
        >
          <el-form-item>
            <h3>{{ pageTitle }}</h3>
          </el-form-item>

            <el-form-item label="包装盒费用">
                <el-col :span="10">
                    <el-input type='number'  min="0" v-model="free.price"></el-input>
                </el-col>
            </el-form-item>

             <el-form-item label="是否开启稍后支付">
                <el-radio-group v-model="isOpenShZf">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="用户存在未支付订单时，禁止下单">
                <el-radio-group v-model="iamWzf">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    size='medium'
                    @click="createFree"
                >保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import OrderService from '@admin/services/OrderService'
import flatry from '@admin/utils/flatry'
export default {
  name: 'OrderSetting',
  data () {
    return {
      pageTitle: '下单设置',
      free: {
        price: '0'
      },
      iamWzf: 0,
      isOpenShZf: 0
    }
  },
  async created () {
    const { data } = await flatry(OrderService.freePrice())
    if (data) {
      if (data.data.body && data.data.body.price) {
        this.free.price = data.data.body.price
        this.iamWzf = data.data.body.iamWzf || 0
        this.isOpenShZf = data.data.body.isOpenShZf || 0
      }
    }
  },
  methods: {
    async createFree () {
      let param = {
        price: this.free.price,
        iamWzf: this.iamWzf,
        isOpenShZf: this.isOpenShZf
      }
      const { data } = await flatry(OrderService.freePrice(param))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/order/setting' })
      }
    }
  }

}
</script>
<style lang='scss' scoped>
</style>
